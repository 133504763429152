<div class="tw-fixed tw-bottom-8 tw-z-50 tw-left-1/2 tw-transform tw--translate-x-1/2 lg:!tw-min-w-[850px] lg:!tw-w-[50%] tw-w-[95%]" id="batch-operations-container"
  [shown]="listObject && listObject.getSelected().length > 0 && hasAvailableOperations()">

  <div class="tw-flex tw-items-center tw-gap-4 tw-py-2 tw-px-4 tw-bg-gray-800 tw-text-white tw-rounded-full">
    <button mat-icon-button aria-label="Close" class="hover:tw-bg-gray-700" (click)="listObject.deselectAll()">
      <mat-icon svgIcon="close" />
    </button>

    <span class="tw-text-2xl tw-text-slate-400">{{listObject?.getSelected().length}} Selected</span>

    <button mat-button class="!tw-text-rise-green-500 tw-text-2xl hover:tw-bg-gray-700" (click)="listObject.selectAll()">
      {{listObject?.search.selectAll ? 'Select None' : 'Select All'}}
    </button>

    <div class="tw-flex-grow"></div>

    @for (operation of listObject?.batchOperations?.operations; track operation) {
    @if (operation.mainOperation) {
    <button mat-button class="!tw-text-white !tw-fill-white hidden lg:!tw-flex hover:tw-bg-gray-700"
      [id]="'action' + operation.name.split(' ').join('')"
      [ngClass]="{'rise-admin-only': operation.requireRole === 'sa'}" (click)="executeOperation(operation)"
      [hidden]="operation.hidden && operation.hidden()">
      <mat-icon [ariaLabel]="operation.name" class="!tw-w-[16px] !tw-h-[16px] !tw-scale-100" [svgIcon]="getOperationIcon(operation.name)"/>
      <span class="tw-ml-2 tw-font-normal">{{operation.name}}</span>
    </button>
    <div class="tw-w-[1px] tw-bg-gray-600 tw-h-10 hidden lg:!tw-block" *ngIf="!$last"></div>
    }
    }

    <button mat-icon-button aria-label="More actions" class="hover:tw-bg-gray-700" [matMenuTriggerFor]="moreMenu" id="more-actions-button"
      *ngIf="additionalOperations?.length > 0">
      <mat-icon svgIcon="more-vertical"/>
    </button>
  </div>
</div>

<mat-menu #moreMenu="matMenu" class="tw-bg-gray-800 tw-rounded-xl tw-w-[300px]">
  <div class="tw-text-white tw-text-2xl tw-py-2 tw-px-6">Actions</div>
  @for (operation of listObject?.batchOperations?.operations; track operation) {
    @if (operation.mainOperation) {
      <button mat-menu-item class="tw-text-white lg:!tw-hidden hover:tw-bg-gray-700 tw-px-6"
        [ngClass]="{'rise-admin-only': operation.requireRole === 'sa'}"
        [id]="'action' + operation.name.split(' ').join('') + 'Mobile'"
        [hidden]="operation.hidden && operation.hidden()"
        (click)="executeOperation(operation)">
        <mat-icon [ariaLabel]="operation.name" class="!tw-mr-4 tw-fill-gray-400 !tw-w-[16px] !tw-h-[16px] !tw-scale-100"
          [svgIcon]="getOperationIcon(operation.name)" />
        {{operation.name}}
      </button>
    }
  }

  <mat-divider class="tw-bg-slate-700 lg:!tw-hidden !tw-my-2"
    *ngIf="listObject?.batchOperations?.operations?.length > 0">
  </mat-divider>

  @for (operation of additionalOperations; track operation) {
    @if (operation.separator) {
      <mat-divider class="tw-bg-slate-700 !tw-my-2"></mat-divider>
    } @else {
      <button mat-menu-item class="tw-text-white hover:!tw-bg-gray-700 tw-px-6"
        [ngClass]="{'rise-admin-only': operation.requireRole === 'sa'}"
        [id]="'action' + operation.name.split(' ').join('')"
        [hidden]="operation.hidden && operation.hidden()"
        (click)="executeOperation(operation)">
        <mat-icon [ariaLabel]="operation.name" class="!tw-mr-4 tw-fill-gray-400 !tw-w-[16px] !tw-h-[16px] !tw-scale-100"
          [svgIcon]="getOperationIcon(operation.name)" />
        {{operation.name}}
      </button>
    }
  }
</mat-menu>

<div id="batchOperationsProgress" class="border-container text-center u_padding-20 u_margin-md-top"
  [shown]="listObject?.batchOperations.activeOperation">
  <div class="multi-actions-progress-panel">
    <p class="mb-0"><strong>Bulk {{listObject?.batchOperations.activeOperation?.name | lowercase}} in
        progress.</strong>&nbsp;<a class="madero-link u_clickable"
        (click)="listObject?.batchOperations.cancel()">Cancel</a></p>
    <p>Please don't navigate away from this page.</p>
    <div class="progress my-4">
      <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{listObject?.batchOperations.progress}}.0"
        attr.aria-valuemin="0" attr.aria-valuemax="100" [style.width]="listObject?.batchOperations.progress + '%'">
      </div>
    </div>
    <p class="mb-0">
      {{listObject?.batchOperations.completedItemCount}} out of {{listObject?.batchOperations.totalItemCount}}
      operations completed.
      <!--
      {{listObject?.batchOperations.name | lowercase}}<span [shown]="listObject?.batchOperations.totalItemCount > 1">s</span>
      {{listObject?.batchOperations.activeOperation.name | lowercase}}d.
      -->
    </p>
  </div>
</div>